import React from 'react';

import { useRouter } from 'next/router';

export default function InsticatorCommentButton({ pageId, pageUrl, canAccessPost }) {
  const [reloadKey, setReloadKey] = React.useState(0);
  const router = useRouter();

  function handleClick() {
    const InsticatorUnit = document.querySelector('.insticator-product-group');
    if (!InsticatorUnit) {
      if (canAccessPost) {
        return;
      } else {
        const paywall = document.getElementById('payed-article-paywall');
        if (paywall) {
          paywall.scrollIntoView({ behavior: 'smooth' });
          return;
        }
      }
    }
    InsticatorUnit.scrollIntoView({ behavior: 'smooth' });
  }
  React.useEffect(() => {
    setReloadKey((prev) => prev + 1);
  }, [router.asPath]);
  return (
    <div
      onClick={handleClick}
      style={{
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a
        data-page-id={pageId}
        href={`${pageUrl}#insticator-commenting`}
        key={`${reloadKey}`}
        style={{
          pointerEvents: 'none',
        }}
      />
    </div>
  );
}
