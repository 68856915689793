/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { FC, useCallback, useEffect, useState } from 'react';

import { GetStaticPaths, GetStaticProps } from 'next';
import { ArticleJsonLd } from 'next-seo';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { buildURL } from 'react-imgix';

import BreadCrumbs from '@/components/breadcrumbs/Breadcrumbs';
import ClipTeaserStacked from '@/components/clips/ClipsTeaserStacked';
import { Container, Heading, Paragraph, Text, useUIDispatch } from '@/components/design-system';
import { VideoPaywallBox } from '@/components/paywall';
import Main from '@/components/shared/Main';
import Sharebar from '@/components/shared/Sharebar';
import { Carousel } from '@/components/ui';
import { MoviePageMainContent } from '@/components/videos/MoviePageMainContent';
import CastAndCrew from '@/components/videos/run-hide-fight/CastAndCrew';
import Reviews from '@/components/videos/run-hide-fight/Reviews';
import { useAuth0 } from '@/context/auth';
import castData from '@/data/cast-crew.json';
import { appApi, Clip, GetVideoBySlugQuery, Video } from '@/graphql/app';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { APP_STORE_ID, SITE_URL } from '@/lib/constants';
import { getUtmCookieData } from '@/lib/utm_cookie';
import {
  DefaultVideoPaywall,
  WhatIsAWomanPaywall,
  TheGreatestLiePaywall,
  TerrorOnThePrairiePaywall,
} from '@/marketing/links';
import { useVideo } from '@/request/video';
import { VideoMetadata } from '@/types/videos';
import { continentKeyToName, stripHtml } from '@/utils/helpers';
import { getStoryBoardURL } from '@/request/mux/storyboard';

const MoviePlayer = dynamic(() => import('@/components/videos/MoviePlayer'), {
  ssr: false,
});
const ConsentForm = dynamic(() => import('@/components/av-player/ConsentForm'), { ssr: false });
const LocationNotAllowedNotice = dynamic(() => import('@/components/av-player/LocationNotAllowedNotice'), {
  ssr: false,
});

interface VideoPageProps {
  videoData: GetVideoBySlugQuery;
  storyBoardURL: string;
}

const VideoPage: FC<VideoPageProps> = ({ videoData, storyBoardURL }) => {
  const { isAuthenticated, loginWithRedirect, subscriber } = useAuth0();
  const uiDispatch = useUIDispatch();
  const router = useRouter();
  const [isOver18, setIsOver18] = useState(false);

  const { data, mutate: mutateVideo } = useVideo(videoData);
  const video = data?.video;

  const handlePaywallCtaClick = useCallback(() => {
    switch (videoData.video?.slug) {
      case 'the-greatest-lie-ever-sold':
        router.push(TheGreatestLiePaywall.href);
        break;

      case 'what-is-a-woman':
        router.push(WhatIsAWomanPaywall.href);
        break;

      case 'terror-on-the-prairie':
        router.push(TerrorOnThePrairiePaywall.href);
        break;

      default:
        router.push(DefaultVideoPaywall.href);
        break;
    }
  }, [videoData, router]);

  const handleLoginClick = useCallback(() => {
    loginWithRedirect({
      appState: { targetUrl: window.location.pathname },
    });
  }, [loginWithRedirect]);

  useEffect(() => {
    if (localStorage.getItem('isOver18')) {
      setIsOver18(true);
    }
  }, []);

  useEffect(() => {
    if (video) {
      const abTestData = getABTestAnalyticData();
      const utmCookieData = getUtmCookieData();

      window.__DW_Next_Bridge?.Analytics.logEvent('Segment Page', {
        ...abTestData,
        ...utmCookieData,
        title: video?.name,
      });
    }
  }, [video]);

  // Meta
  const absUrl = `${SITE_URL}/videos/${video?.slug}`;
  const description = video?.description ? stripHtml(video.description) : '';
  const metaImageUrl = video?.image ? buildURL(video.image, { w: 1200, h: 675 }) : '';
  const parselyPubDate = video?.createdAt ? new Date(video.createdAt).toISOString().split('.')[0] + 'Z' : '';

  // Content Ratings
  const rating = video?.rating;
  const ratingIsAllowed = (rating === 'TV-MA' && isOver18) || rating !== 'TV-MA';

  // Continent Playback
  const locationIsAllowed = video?.videoURL && video.videoURL !== 'Access Restricted';

  // Member access
  const videoIsAllowed = video?.videoURL && video.videoURL !== 'Access Denied';

  // Conditional display logic
  const hasAuthAccess =
    isAuthenticated && subscriber?.effectivePlan && videoData?.video?.videoAccess?.includes(subscriber.effectivePlan);
  const showAccessDenied = !videoIsAllowed;
  const showLocationNotAllowed = !showAccessDenied && !locationIsAllowed;
  const showConsentForm = !showAccessDenied && !showLocationNotAllowed && !ratingIsAllowed;
  const showPlayer = !showAccessDenied && !showLocationNotAllowed && !showConsentForm;
  const showAvailableLocations = video?.allowedContinents?.length !== 0 || video?.allowedCountryNames?.length !== 0;

  // Videodata
  const metadata = video?.metadata as VideoMetadata;

  const castCrew = castData[video?.slug] || null;

  const imageUrl =
    video?.slug === 'the-greatest-lie-ever-sold'
      ? '/images/the-greatest-lie-ever-sold.jpg'
      : video?.slug === 'my-dinner-with-trump'
      ? '/images/dinner_with_trump.jpg'
      : video?.image;

  return (
    <Main>
      <Head>
        <meta content='Video' name='parsely-section' />
        <meta content='' name='parsely-tags' />
        <meta content={parselyPubDate} name='parsely-pub-date' />
        <meta content={absUrl} name='parsely-link' />
        {metaImageUrl && <meta content={metaImageUrl} name='parsely-image-url' />}
        <title>{video?.name ?? ''}</title>
        <link href={absUrl} rel='canonical' />
        <meta content='website' property='og:type' />
        <meta content={description} name='description' />
        <meta content={video?.name ?? ''} property='og:title' />
        <meta content={description} property='og:description' />
        {metaImageUrl && <meta content={metaImageUrl} property='og:image' />}
        <meta content={absUrl} property='og:url' />
        <meta content={video?.name ?? ''} property='twitter:title' />
        <meta content={description} property='twitter:description' />
        <meta content='summary_large_image' name='twitter:card' />
        {metaImageUrl && <meta content={metaImageUrl} name='twitter:image' />}
        <meta content={`app-id=${APP_STORE_ID}, app-argument=${absUrl}`} name='apple-itunes-app' />
        <meta content={absUrl} property='al:ios:url' />
        <meta content={absUrl} property='al:android:url' />
        <meta content={absUrl} name='twitter:app:url:iphone' />
        <meta content={absUrl} name='twitter:app:url:ipad' />
        <meta content={absUrl} name='twitter:app:url:googleplay' />
      </Head>
      <ArticleJsonLd
        authorName={`${video?.createdBy?.firstName} ${video?.createdBy?.lastName}`}
        dateModified={video?.updatedAt}
        datePublished={video?.createdAt}
        description={description}
        images={[metaImageUrl]}
        publisherLogo={`${SITE_URL}/images/logo.png`}
        publisherName='The Daily Wire'
        title={video?.name ?? ''}
        url={absUrl}
      />

      <MoviePageMainContent>
        <div className='video-container' css={{ width: '100%', maxWidth: '960px' }}>
          <div
            css={(theme) => ({
              position: 'relative',
              minHeight: '150px',
              [theme.mq.desktop]: {
                minHeight: '300px',
              },
            })}
          >
            {showPlayer ? (
              <MoviePlayer mutateVideo={mutateVideo} storyBoardURL={storyBoardURL} video={video as Video} />
            ) : (
              <>
                {showAccessDenied && !hasAuthAccess && (
                  <VideoPaywallBox
                    imageUrl={imageUrl}
                    isAuthenticated={isAuthenticated}
                    onLoginClick={handleLoginClick}
                    onPaywallCtaClick={handlePaywallCtaClick}
                    requiredAccess={video?.videoAccess}
                    userPlanCode={subscriber?.effectivePlan}
                  />
                )}

                {showConsentForm && (
                  <ConsentForm
                    contentType='video'
                    onClose={() => uiDispatch({ type: 'CLOSE_VIDEO_OVERLAY' })}
                    onSuccess={() => setIsOver18(true)}
                  />
                )}

                {showLocationNotAllowed && (
                  <LocationNotAllowedNotice
                    contentType='video'
                    onClose={() => uiDispatch({ type: 'CLOSE_VIDEO_OVERLAY' })}
                  />
                )}
                {video.videoURL === null && imageUrl && (
                  <div>
                    <img alt={video?.name} css={{ width: '100%', height: 'auto', display: 'block' }} src={imageUrl} />
                  </div>
                )}
              </>
            )}
          </div>

          <div
            css={(theme) => ({
              padding: '0 24px',
              marginTop: 24,
              [theme.mq.desktop]: {
                padding: 0,
              },
            })}
          >
            <Heading css={{ color: '#fff' }}>{video?.name}</Heading>
            <div
              css={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                color: theme.colors.gray20,
              })}
            >
              {video?.rating && (
                <Text
                  css={(theme) => ({
                    border: `1px solid ${theme.colors.gray60}`,
                    color: theme.colors.gray20,
                    padding: '.25rem .5rem',
                    borderRadius: '.25rem',
                    marginRight: '.5rem',
                    flexShrink: 0,
                  })}
                  variant='100'
                >
                  {video.rating}
                </Text>
              )}

              {video && showAvailableLocations && (
                <Text variant='100'>
                  Available in{' '}
                  {video?.allowedContinents?.map((continentKey, index) => (
                    <React.Fragment key={continentKey}>
                      {continentKeyToName(continentKey)}
                      {video?.allowedContinents.length !== index + 1 && ', '}
                    </React.Fragment>
                  ))}
                  {video?.allowedCountryNames?.map((countryName, index) => (
                    <React.Fragment key={countryName}>
                      {countryName}
                      {video?.allowedCountryNames.length !== index + 1 && ', '}
                    </React.Fragment>
                  ))}
                </Text>
              )}
            </div>

            {video?.description && (
              <Paragraph
                css={(theme) => ({
                  color: theme.colors.white,
                  marginTop: 8,
                  marginBottom: 24,
                  lineHeight: '1.5',
                })}
              >
                {video.description}
              </Paragraph>
            )}

            <Sharebar author='' title={video?.name ?? ''} type='Video' url={`${SITE_URL}/videos/${video?.slug}/`} />
          </div>
        </div>
      </MoviePageMainContent>

      {video?.clips && video.clips.length !== 0 && (
        <div
          css={{
            paddingBottom: '5rem',
            marginTop: '3rem',
          }}
        >
          <Container>
            <Carousel columns={4} kicker='Extras' mode='dark' offsetControls='16:9' tabletColumns={3}>
              {video.clips.map((clip) => (
                <div key={clip.id}>
                  <ClipTeaserStacked clip={clip as Clip} />
                </div>
              ))}
            </Carousel>
          </Container>
        </div>
      )}

      {(castCrew || metadata?.reviews) && (
        <Container css={{ margin: '56px auto', maxWidth: '1000px !important' }}>
          {castCrew && <CastAndCrew cast={castCrew} />}
          {metadata?.reviews && (
            <>
              <div className='Watch__HorizontalGradient' style={{ margin: '40px auto' }} />
              <Reviews reviews={metadata.reviews} />
            </>
          )}
        </Container>
      )}
      <div style={{ height: '50px' }} />
      <BreadCrumbs
        links={[<Link href='/'>DailyWire+</Link>, <Link href='/watch/movies'>Watch</Link>, <>{video?.name}</>]}
      />
    </Main>
  );
};

export default VideoPage;

export const getStaticProps: GetStaticProps = async (context) => {
  const slug = context.params?.slug as string;
  const videoData = await appApi.getVideoBySlug({ slug });
  const storyBoardData = await getStoryBoardURL('video', slug);

  // TODO: Create issue for necessitating this PUBLISHED check. Non-published
  // items should not return from the API alltogether
  if (!videoData.video || videoData.video.status !== 'PUBLISHED' || slug == 'terror-on-the-prairie') {
    return {
      notFound: true,
      revalidate: 600,
    };
  }

  return {
    props: { videoData, storyBoardURL: storyBoardData?.video?.muxStoryBoardURL },
    revalidate: 600, // 10 mins (in seconds)
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const { videos } = await appApi.getVideoSlugs({ first: 10 });

  const paths = videos.map((video) => {
    const slug = video?.slug as string;
    if (slug != 'terror-on-the-prairie') {
      return { params: { slug } };
    } else {
      return { params: { slug: 'jdhshdshgydsgghdshgs' } };
    }
  });

  return { paths, fallback: 'blocking' };
};
