import * as React from 'react';

import Image from 'next/image';

import { Heading, Text } from '@/components/design-system';
import { VideoMetadataCast } from '@/types/videos';

interface ICastAndCrewProps {
  cast: VideoMetadataCast;
}

const CastAndCrew: React.FC<ICastAndCrewProps> = ({ cast }) => {
  return (
    <div css={{ color: '#fff', padding: '0 24px', flexWrap: 'wrap' }}>
      <Heading variant='600'>{cast.title}</Heading>
      <div
        css={(theme) => ({
          display: 'grid',
          gridTemplateColumns: 'repeat(2,1fr)',
          gridGap: '32px',
          marginBottom: '56px',
          marginTop: '32px',

          [theme.mq.tablet]: { gridTemplateColumns: 'repeat(4,1fr)' },
        })}
      >
        {cast.data.map((crew) => (
          <div key={crew.name}>
            <div
              css={(theme) => ({
                width: '100%',
                maxWidth: '120px',
                marginBottom: '1rem',

                img: {
                  borderRadius: '50%',
                },

                [theme.mq.tablet]: {
                  maxWidth: '154px',
                },
              })}
            >
              <Image alt={crew.name} height={154} layout='responsive' src={crew.image} width={154} />
            </div>
            <Heading variant='400'>{crew.name}</Heading>
            <Text css={{ marginTop: ' 4px', color: '#CCC' }} variant='200'>
              {crew.role}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CastAndCrew;
