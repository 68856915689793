/* eslint-disable react/no-array-index-key */
import * as React from 'react';

import { Heading, Text } from '@/components/design-system';
import { VideoMetadataReviews } from 'types/videos';

interface IReviewsProps {
  reviews: VideoMetadataReviews;
}

const Reviews: React.FC<IReviewsProps> = ({ reviews }) => {
  return (
    <div css={{ color: '#fff' }}>
      <Heading variant='600'>{reviews.title}</Heading>
      <div
        css={(theme) => ({
          display: 'grid',
          gridTemplateColumns: 'repeat(1,1fr)',
          gridGap: '32px',
          marginTop: '32px',

          [theme.mq.phablet]: { gridTemplateColumns: 'repeat(2,1fr)' },
        })}
      >
        {reviews.data.map((review, index) => (
          <div key={`review-${index}`}>
            <div>
              <Text as='div' css={{ marginBottom: '8px' }} variant='300'>
                {review.review}
              </Text>
              <Text as='div' css={{ color: '#CCC' }} variant='100'>
                {review.reviewSource}
              </Text>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;
