/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';

import { useRouter } from 'next/router';
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share';

import { getABTestAnalyticData } from '@/lib/abTesting';
import { SITE_URL } from '@/lib/constants';
import { getUtmCookieData } from '@/lib/utm_cookie';
import { visuallyHidden } from '@/utils/styles';

import InsticatorCommentButton from './InsticatorCommentButton';

interface ISharebarProps {
  // eslint-disable-next-line react/no-unused-prop-types
  type: string;
  title: string;
  // eslint-disable-next-line react/no-unused-prop-types
  author: string;
  url?: string;
  insticator?: boolean | null;
  id?: number;
  canAccessPost?: boolean;
}

const shareButtonStyles = (theme: any) => ({
  height: 28,
  width: 28,
  borderRadius: 100,
  color: theme.colors.red,
  overflow: 'hidden',
  outline: 'none',
  cursor: 'pointer',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  svg: {
    width: 'auto',
    height: '40%',
  },
});

const segmentShareEvent = (method: 'Facebook' | 'Twitter' | 'Email', props: ISharebarProps) => {
  const abTestData = getABTestAnalyticData();
  const utmCookieData = getUtmCookieData();

  window.__DW_Next_Bridge?.Analytics.logEvent(`Content Shared`, {
    ...abTestData,
    ...utmCookieData,
    share_via: method,
    title: props.title,
    type: props.type,
    author: props.author,
    url: props.url,
  });
};

const Sharebar: React.FC<ISharebarProps> = (props) => {
  const router = useRouter();
  const title = props.title;
  const url = props.url ?? `${SITE_URL}/${router.asPath}`;

  return (
    <div
      style={{
        display: 'flex',
        gap: '12px',
        alignItems: 'center',
      }}
    >
      <FacebookShareButton
        // Currently react-share does not have a callback for shared successfully.
        // Comments in Github suggest using onShareWindowClose() in the meantime
        onShareWindowClose={() => segmentShareEvent('Facebook', props)}
        url={url}
      >
        <span css={visuallyHidden}>Facebook</span>
        <div css={(theme) => [shareButtonStyles(theme), { backgroundColor: '#3a5999' }]}>
          <svg height='16' viewBox='0 0 10 16' width='10' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M3.02293,16 L3,9 L0,9 L0,6 L3,6 L3,4 C3,1.3008 4.67151,0 7.07938,0 C8.23277,0 9.22406,0.08587 9.51294,0.12425 L9.51294,2.94507 L7.84296,2.94583 C6.53343,2.94583 6.27987,3.5681 6.27987,4.48124 L6.27987,6 L10,6 L9,9 L6.27986,9 L6.27986,16 L3.02293,16 Z'
              fill='#FFF'
            />
          </svg>
        </div>
      </FacebookShareButton>

      <TwitterShareButton onShareWindowClose={() => segmentShareEvent('Twitter', props)} title={title} url={url}>
        <div css={(theme) => [shareButtonStyles(theme), { backgroundColor: '#1b1b1b' }]}>
          <span css={visuallyHidden}>X</span>
          <svg fill='none' height='14' viewBox='0 0 15 14' width='15' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M11.8131 0H14.1131L9.08813 5.74375L15 13.5581H10.3712L6.74625 8.81813L2.5975 13.5581H0.29625L5.67125 7.41437L0 0.000625029H4.74625L8.02312 4.33313L11.8131 0ZM11.0063 12.1819H12.2806L4.05375 1.30437H2.68625L11.0063 12.1819Z'
              fill='white'
              fillOpacity='0.88'
            />
          </svg>
        </div>
      </TwitterShareButton>

      <EmailShareButton
        beforeOnClick={() => segmentShareEvent('Email', props)}
        subject={title}
        // Intentionally using beforeOnClick() for email because the user
        // is sent off site so we can not leverage onShareWindowClose()
        url={url}
      >
        <div css={(theme) => [shareButtonStyles(theme), { backgroundColor: theme.colors.gray40 }]}>
          <span css={visuallyHidden}>Mail</span>
          <svg height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M26.8749645,12.0937832 C27.1457974,11.94795 27.4114217,11.963575 27.6718376,12.140658 C27.9322535,12.3177411 28.0416283,12.562532 27.9999619,12.8750313 L25.7499672,26.3749991 C25.7083008,26.6041651 25.5780926,26.7812481 25.3593431,26.9062478 C25.1405937,27.0312475 24.9270527,27.041664 24.7187197,26.9374978 L20.812479,25.3125016 L18.8437337,27.7187459 C18.6979005,27.9062455 18.5156095,27.9999952 18.29686,27.9999952 C18.0781105,27.9999952 17.890611,27.9322869 17.7343613,27.7968707 C17.5781117,27.6614545 17.4999869,27.4687465 17.4999869,27.2187471 L17.4999869,24.7187531 L25.0312189,15.531275 C25.0937188,15.4479417 25.0833023,15.3698169 24.999969,15.2969005 C24.9166357,15.2239842 24.8333029,15.2187757 24.7499696,15.2812756 L15.781241,23.1875067 L12.4687489,21.81251 C12.1770831,21.6875103 12.0208334,21.4687608 12,21.1562615 C11.9791666,20.8437623 12.1145832,20.6145963 12.406249,20.4687632 L26.8749645,12.0937832 Z'
              fill='#FFF'
              transform='translate(-12 -12)'
            />
          </svg>
        </div>
      </EmailShareButton>
      {props.insticator && (
        <InsticatorCommentButton canAccessPost={props.canAccessPost} pageId={props.id} pageUrl={url} />
      )}
    </div>
  );
};

export default Sharebar;
